import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'

const FourOhFour = () => (
  <Layout>
    <main className="text-white pt-32">
      <div className="max-w-md mx-auto p-3">
        <h1 className="text-4xl uppercase mb-10 font-demi font-bold">404 - Borte vekk
        </h1>
        <div className="flex flex-col space-y-7">
          <p>Sorry, men vi fant ikke det du spurte etter.</p>
          <p>
            <Link to="/">Gå til forsiden</Link>.
          </p>
        </div>
      </div>
    </main>
  </Layout>
)

export default FourOhFour
