export const videoEnded = (ended) => {
  return {
    type: 'VIDEO_ENDED',
    payload: ended,
  }
}
export const setCurrentVideoTime = (currentTime) => {
  return {
    type: 'SET_CURRENT_VIDEO_TIME',
    payload: currentTime,
  }
}
export const setPlayer = (player) => {
  return {
    type: 'SET_VIDEO_PLAYER',
    payload: player,
  }
}
export const setPlaybackRate = (rate) => {
  return {
    type: 'SET_PLAYBACK_RATE',
    payload: rate,
  }
}
export const setMuted = (mute) => {
  return {
    type: 'SET_MUTED',
    payload: mute,
  }
}

