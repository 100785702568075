const menuHandle = (e, type) => {
  const menu = e.current
  if (type === 'open') {
    document.body.classList.add('menu-on')
    menu.classList.remove('hidden')
    setTimeout(() => {
      menu.classList.remove('opacity-0')
      menu.classList.remove('-translate-y-10')
    }, 50)
    return
  }
  if (type === 'close') {
    document.body.classList.remove('menu-on')
    menu.classList.add('opacity-0')
    menu.classList.add('-translate-y-10')

    setTimeout(() => {
      menu.classList.add('hidden')
    }, 500)
  }
}

const removeMenu = () => {
  document.body.classList.remove('menu-on')
}

const filterBtn = () => {
  const filter = document.querySelectorAll('.btn-filter')
  function removeClass() {
    filter.forEach((el) => {
      el.classList.remove('active')
    })
  }
  filter.forEach((el, index) => {
    if (index === 0) el.classList.add('active')
    el.addEventListener('click', function () {
      removeClass()
      this.classList.add('active')
    })
  })
}

const filterButtons = () => {
  const filter = document.querySelectorAll('.filter-buttons')
  function removeClass(parent) {
    parent.querySelectorAll('.filter-buttons').forEach((el) => {
      el.classList.remove('active')
    })
  }
  filter.forEach((el, index) => {
    el.addEventListener('click', function () {
      removeClass(this.parentNode)
      this.classList.add('active')
    })
  })
}

const removeFilter = () => {
  const filter = document.querySelectorAll('.filter-buttons')
  filter.forEach((el) => {
    el.classList.remove('active')
  })
}

export { menuHandle, filterBtn, filterButtons, removeFilter, removeMenu }
